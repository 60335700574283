import { observable, action, makeAutoObservable, runInAction } from 'mobx'

import {
  getPrivilegi,
  getPrivilegio,
  creaPrivilegio,
  modificaPrivilegio,
  eliminaPrivilegio,
  attribuisciPrivilegiModulo,
} from './../rest/gestionePrivilegi'

import { IPrivilegio } from '../../../types'

export class GestionePrivilegiStore {

  privilegi: IPrivilegio[] = []

  creatingPrivilegio: boolean = false
  creatingPrivilegioError: string = ''

  editingPrivilegio: boolean = false
  modificaPrivilegioError: string = ''
  privilegioModificato: boolean = false

  deletingPrivilegio: boolean = false
  eliminaPrivilegioError: string = ''
  privilegioEliminato: boolean = false

  //Relazioni
  doingAttribuisciPrivilegio: boolean = false
  attribuisciPrivilegioError: string = ''
  privilegioAttribuito: boolean = false

  constructor() {
    makeAutoObservable(this, {

      privilegi: observable,
      getPrivilegi: action,

      //Crea privilegio
      creaPrivilegio: action,
      creatingPrivilegio: observable,
      creatingPrivilegioError: observable,

      //Modifica privilegio
      modificaPrivilegio: action,
      editingPrivilegio: observable,
      modificaPrivilegioError: observable,
      privilegioModificato: observable,

      //Elimina privilegio
      eliminaPrivilegio: action,
      deletingPrivilegio: observable,
      eliminaPrivilegioError: observable,
      privilegioEliminato: observable,

      //Relazioni
      attribuisciPrivilegiModulo: action,
      doingAttribuisciPrivilegio: observable,
      attribuisciPrivilegioError: observable,
      privilegioAttribuito: observable
    })
  }

  async getPrivilegi() {
    await getPrivilegi().then(res => {

      runInAction(() => this.privilegi = res.data.data)


    }).catch(err => {

      console.log(err)

    })
  }

  async getPrivilegio(id: number) {
    await getPrivilegio(id).then(res => {

      runInAction(() => this.privilegi = res.data.data)

    }).catch(err => {

      console.log(err)

    })
  }

  //Crea un privilegio
  async creaPrivilegio(privilegio: IPrivilegio) {

    this.creatingPrivilegio = true

    await creaPrivilegio(privilegio).then(res => {

      runInAction(() => this.creatingPrivilegioError = '')

    }).catch(err => {

      runInAction(() => this.creatingPrivilegioError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.creatingPrivilegio = false)

    })
  }


  async modificaPrivilegio(privilegio: IPrivilegio,) {

    this.editingPrivilegio = true

    await modificaPrivilegio(privilegio).then(res => {

      runInAction(() => this.modificaPrivilegioError = '')

    }).catch(err => {

      runInAction(() => this.modificaPrivilegioError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingPrivilegio = false)

      runInAction(() => this.privilegioModificato = !this.privilegioModificato)

    })
  }

  async toggleAttivazionePrivilegio(privilegio: IPrivilegio) {

    this.editingPrivilegio = true

    await modificaPrivilegio(privilegio).then(res => {

      runInAction(() => this.modificaPrivilegioError = '')

    }).catch(err => {

      runInAction(() => this.modificaPrivilegioError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingPrivilegio = false)

      runInAction(() => this.privilegioModificato = !this.privilegioModificato)

    })
  }

  async eliminaPrivilegio(id: number) {

    this.deletingPrivilegio = true

    await eliminaPrivilegio(id).then(res => {

      runInAction(() => this.eliminaPrivilegioError = '')

    }).catch(err => {

      runInAction(() => this.eliminaPrivilegioError = err.response.data.message ? err.response.data.message : 'Errore generico')

      console.log(this.eliminaPrivilegioError)

    }).finally(() => {

      runInAction(() => this.deletingPrivilegio = false)

      runInAction(() => this.privilegioEliminato = !this.privilegioEliminato)
    }
    )
  }

  //Relazioni
  async attribuisciPrivilegiModulo(modulo_codice: string, privilegi: []) {

    this.doingAttribuisciPrivilegio = true

    await attribuisciPrivilegiModulo(modulo_codice, privilegi).then(res => {

      runInAction(() => this.attribuisciPrivilegioError = '')

    }).catch(err => {

      runInAction(() => this.attribuisciPrivilegioError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.doingAttribuisciPrivilegio = false)

      runInAction(() => this.privilegioAttribuito = !this.privilegioAttribuito)

    }
    )
  }

}
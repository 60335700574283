import React from 'react'

//Data
import {useStores} from '../../hooks/useStores'
import {observer} from 'mobx-react-lite'

//Components
import IpeLogo from './IpeLogo'
import Menu from './Menu'
import {Link} from 'react-router-dom'
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa'

export const Navbar = observer(() => {

    const {ui} = useStores();

    const styles = {
        navbar: `h-screen bg-lightgray-d dark:bg-darkgray-d dark:bg-darkgray-d md:flex flex-col md:shadow-none border-r border-lightgray-dd dark:border-darkgray-dd z-10 md:z-[100]
    ${ui.mainNavFull ? 'min-w-[300px] max-w-[300px]' : 'w-auto'}
    ${ui.mainNavMobile ? 'shadow-2xl block fixed w-[80vw] left-0 top-0 w-[300px]' : 'hidden'}`,
        navbarHeader: `px-2 md:px-4 min-h-[70px] w-full bg-lightgray-d dark:bg-darkgray-d flex items-center relative ${!ui.mainNavFull && 'justify-center'}`,
        menuWrapper: `${ui.mainNavFull ? 'p-2' : 'p-1'} flex-grow max-h-full overflow-y-auto`,
        reduceButton: "w-[14px] h-[14px] bg-lightgray-d dark:bg-darkgray-d rounded-full border border-lightgray-dd dark:border-darkgray-dd items-center justify-center hidden md:flex absolute right-[-7px] cursor-pointer hover:bg-lightgray-d dark:bg-darkgray-dd"
    }

    return (
        <aside className={styles.navbar}>

            {/* Header */}
            <header className={styles.navbarHeader}>

                <Link className="flex justify-center flex-col" to="/">
                    <IpeLogo width={20}/>
                    <h1 className="font-bold text-xl text-text dark:text-dark-text">
                        {process.env.REACT_APP_NOME_CLIENTE}
                    </h1>
                </Link>

                {/* Reduce button */}
                <div className={styles.reduceButton} onClick={() => ui.toggleMainNavFull()}>
                    {ui.mainNavFull ? <FaAngleLeft size={8}/> : <FaAngleRight size={8}/>}
                </div>

            </header>

            {/* Menu */}
            <div className={styles.menuWrapper}>
                <Menu/>
            </div>

        </aside>
    )
});
